import React from "react";

function About() {

    return (
        <div class="card d-flex h-100 text-center align-self-center">
            
          <h1>Our App Is All About Reading.So do not forget to Read and READ ^^</h1>
          <img src="https://images.unsplash.com/photo-1623031345438-387dd3c45e9f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NTR8fGxpYnJhcnl8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60" alt="img"></img>
          
        </div>
    )
}

export default About